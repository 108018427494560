import { Input, Tabs } from "antd";
import { graphql, navigate } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { FC, useMemo, useState } from "react";
import { useQueryParam } from "use-query-params";
import { SearchPageQuery } from "../../graphql-types";
import {
  MainContainer,
  PageContainer,
  SkewMainContainer,
} from "../components/Container";
import ContentfulImage from "../components/ContentfulImage";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import { LeftContentRightButton } from "../components/Layout";
import Metadata from "../components/Metadata";
import { usePageMetadata } from "../metadata/page";

const { TabPane } = Tabs;

const ResultItem = (props: SearchResultType) => {
  const { text, linkPrefix } = useTypename(props.__typename);
  return (
    <Link
      to={`/${linkPrefix}/${props?.slug}`}
      className="flex mb-10 cursor-pointer"
      onClick={() => navigate("/")}
    >
      <ContentfulImage image={props.preview_logo!} className="w-24 rounded" />
      <div className="ml-6">
        <h4 className="text-indigo-600 font-bold text-lg">{props.title}</h4>
        <p className="text-blue text-gray-700 my-1">{props.preview}</p>
        <p className="text-gray-500">{text}</p>
      </div>
    </Link>
  );
};

type SearchResultType =
  | SearchPageQuery["allContentfulScenario"]["nodes"][number]
  | SearchPageQuery["allContentfulCaseStudy"]["nodes"][number];

const useTypename = (
  typename: "ContentfulScenario" | "ContentfulCaseStudy"
) => {
  const { t } = useTranslation();
  switch (typename) {
    case "ContentfulScenario":
      return {
        text: t("服务场景"),
        linkPrefix: "scenario",
      };
    case "ContentfulCaseStudy":
      return {
        text: t("客户案例"),
        linkPrefix: "caseStudy",
      };
    default:
      throw new Error(`not exit typename ${typename}`);
  }
};

function performSearch(
  allItems: SearchResultType[],
  search: string,
  filter: "all" | "ContentfulScenario" | "ContentfulRelatedCases"
) {
  const keys = [
    "title",
    "preview",
    "description",
    "keywords",
    "detailHtml",
  ] as const;
  return allItems
    .filter((i) => filter === "all" || i.__typename === filter)
    .filter(
      (i) =>
        !search ||
        keys.some((k) => i[k]?.toLowerCase().includes(search.toLowerCase())) ||
        i.solutions?.some((s) =>
          s.name.toLowerCase().includes(search.toLowerCase())
        )
    )
    .sort((a, b) => {
      for (const k of keys) {
        const aMatches = a[k]?.toLowerCase().includes(search.toLowerCase());
        const bMatches = b[k]?.toLowerCase().includes(search.toLowerCase());
        if (aMatches && !bMatches) {
          return -1;
        }
        if (!aMatches && bMatches) {
          return 1;
        }
      }
      return 1;
    });
}

const SearchPage: FC<{ data: SearchPageQuery }> = ({
  data: { allContentfulScenario },
}) => {
  const allItems = useMemo(
    () =>
      [...allContentfulScenario.nodes!].map((item) => ({
        ...item,
        description: item.description?.description,
        keywords: item.keywords?.keywords,
        detailHtml: item.detailHtml?.detailHtml,
      })),
    [allContentfulScenario]
  );
  const { t } = useTranslation();
  const [keywords] = useQueryParam<string>("keywords");
  const [search, setSearch] = useState(keywords || "");
  const tabs = [
    { title: t("全部"), value: "all" },
    { title: t("服务场景"), value: "ContentfulScenario" },
    { title: t("相关案例"), value: "ContentfulRelatedCases" },
  ] as const;
  const [filter, setFilter] = useState<typeof tabs[number]["value"]>("all");
  const linkInfo = useMemo(
    () => ({
      title: t("没有找到想要的结果？"),
      content: t("请联系我们的专业销售人员，他们会解答您的所有疑问。"),
      buttonText: t("立即联系我们"),
    }),
    [t]
  );
  const results = useMemo(
    () => performSearch(allItems, search, filter),
    [allItems, search, filter]
  );
  const { search: metadata } = usePageMetadata();
  return (
    <PageContainer>
      <Metadata {...metadata} />
      <Header defaultTheme={ThemeEnum.dark} />
      <MainContainer>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          allowClear
          placeholder={t("搜索业务需求，如: 人脸识别、语音识别")}
          className="w-80 sm:w-3/5 h-10 mt-8 mb-2 rounded-full px-4"
        />
      </MainContainer>
      <MainContainer>
        <Tabs defaultActiveKey="all" onChange={(k) => setFilter(k as any)}>
          {tabs.map((tab) => {
            return <TabPane tab={tab.title} key={tab.value} />;
          })}
        </Tabs>
        <p className="text-gray-500 pt-2 pb-12">
          {t("找到 {{count}} 个相关结果", { count: results.length })}
        </p>
        {results.slice(0, 20).map((item, index) => (
          <ResultItem key={index} {...item} />
        ))}
      </MainContainer>
      <SkewMainContainer fillClassName="bg-white">
        <LeftContentRightButton
          onClick={() => navigate("/contact")}
          {...linkInfo}
        />
      </SkewMainContainer>
      <Footer />
    </PageContainer>
  );
};

export default SearchPage;

export const query = graphql`
  query SearchPage($language: String!) {
    ...AllLangauages
    allContentfulScenario(filter: { node_locale: { eq: $language } }) {
      nodes {
        __typename
        id
        slug
        title
        preview
        description {
          description
        }
        keywords {
          keywords
        }
        detailHtml {
          detailHtml
        }
        preview_logo {
          ...ContentfulImage200x200
        }
        solutions {
          name
          slug
          keywords {
            keywords
          }
          description {
            description
          }
        }
      }
    }
    allContentfulCaseStudy(filter: { node_locale: { eq: $language } }) {
      nodes {
        __typename
        id
        slug
        title
        description {
          description
        }
        keywords {
          keywords
        }
        detailHtml {
          detailHtml
        }
        preview_logo {
          ...ContentfulImage200x200
        }
      }
    }
  }
`;
